AOS.init();

let heroSwiper = new Swiper(".heroSwiper", {
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },

  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },

  speed: 400,
  loop: true,
});

// let clientsSwiper = new Swiper(".clientsSwiper", {
//   autoplay: {
//     delay: 2500,
//     disableOnInteraction: false,
//   },

//   speed: 400,
//   loop: true,
//   slidesPerView: 4,
//   spaceBetween: 0,
// });

window.onload = function () {
  if (screen.width < 600) {
    return (clientsSwiper = new Swiper(".clientsSwiper", {
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },

      speed: 400,
      loop: true,
      slidesPerView: 2,
      spaceBetween: 0,
    }));
  } else {
    return (clientsSwiper = new Swiper(".clientsSwiper", {
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },

      speed: 400,
      loop: true,
      slidesPerView: 4,
      spaceBetween: 70,
    }));
  }
};

let testimonialsSwiper = new Swiper(".testimonialsSwiper", {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },

  allowSlideNext: true,
  speed: 400,
  loop: true,
  slidesPerView: 1,
  direction: "vertical",
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const animationDefiner = () => {
  document.querySelector(".hero__button-container").style.animation =
    "fade-from-left 1s ease-in-out";
  document.querySelector(".hero__left__title").style.animation =
    "fade-from-left 1s ease-in-out";
  document.querySelector(".hero__left__description").style.animation =
    "fade-from-left 1s ease-in-out";
  document.querySelector(".hero__right").style.animation =
    "fade-from-right 1s ease-in-out";
};

window.addEventListener("load", animationDefiner);

let map = L.map("map").setView([-25.446498, -49.245651], 16);

L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  attribution: "© OpenStreetMap",
}).addTo(map);

L.marker([-25.446498, -49.245651]).addTo(map);

document.querySelector(".hamburguer").addEventListener("click", function () {
  document.querySelector(".menu").classList.toggle("menu-active");
  document.querySelector(".hamburguer").classList.toggle("close-hamburguer");
});

document.querySelectorAll(".menu-links").forEach((el) => {
  el.addEventListener("click", () => {
    document.querySelector(".menu").classList.toggle("menu-active");
    document.querySelector(".hamburguer").classList.toggle("close-hamburguer");
  });
});
